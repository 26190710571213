import React from "react";
import { LocalizedRouter } from "gatsby-theme-i18n";

import { ProtectComponent } from "../components/ProtectComponents";
import MyAccountLayout from "../app/components/Layout";
import Dashboard from "../app/containers/Dashboard";
import Orders from "../app/containers/Orders";
import Addresses from "../app/containers/Addresses";
import Offers from "../app/containers/Offers";

const MyAccount = ({ pageContext, location }) => {
  // ==================================
  const { seoData, layoutProps } = pageContext;

  // ==================================

  return (
    <ProtectComponent>
      <MyAccountLayout seo={seoData} location={location} {...layoutProps}>
        <LocalizedRouter basepath={`/myaccount`}>
          <Orders path="/orders" />
          <Addresses path="/addresses" />
          <Offers path="/offers" />
          <Dashboard default />
        </LocalizedRouter>
      </MyAccountLayout>
    </ProtectComponent>
  );
};

export default MyAccount;
