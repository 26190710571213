import React, { useContext, useEffect, useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { CustomerContext } from "../../context";
import _ from "lodash";
import produce from "immer";
import { useUpdateMe } from "../../mutations";
import { navigate } from "gatsby";

const EditProfileModal = ({ show, onHide }) => {
  const { customer } = useContext(CustomerContext);

  const { data, setVariable } = useUpdateMe();

  const [formData, setFormData] = useState({});

  const [disable, setDisable] = useState(true);
  const onSubmit = (e) => {
    e.preventDefault();
    setDisable(true);
    setVariable({
      variables: {
        ...formData,
      },
    });
  };

  useEffect(() => {
    if (_.isEmpty(data)) return;
    navigate(0);
  }, [data]);

  useEffect(() => {
    if (_.isEmpty(customer)) return;
    setFormData({
      firstName: customer.firstName,
      lastName: customer.lastName,
    });
  }, [customer]);
  const onInputChange = ({ target: { type, name, value, checked } }) => {
    setFormData(
      produce(formData, (draft) => {
        draft[name] = type === "checkbox" ? checked : value;
      }),
    );
    setDisable(false);
  };

  return (
    <Modal show={show} onHide={onHide} size="sm" centered>
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton={true}>
          <Modal.Title as="h5" id="edit-profile">
            Edit profile
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-row">
            <Form.Group className="col-md-12">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email || customer.email || ""}
                placeholder="Enter Email"
                onChange={onInputChange}
              />
            </Form.Group>
            <Form.Group className="col-md-12">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                value={formData.firstName || customer.firstName || ""}
                placeholder="Last Name"
                onChange={onInputChange}
              />
            </Form.Group>
            <Form.Group className="col-md-12">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                value={formData.lastName || customer.lastName || ""}
                placeholder="Last Name"
                onChange={onInputChange}
              />
            </Form.Group>

            <Form.Group className="col-md-12">
              <Form.Label>Phone number</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                value={formData.phone || customer.phone || ""}
                placeholder="Enter Phone number"
                onChange={onInputChange}
              />
            </Form.Group>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="row">
            <div className="col-6">
              <Button
                type="button"
                onClick={onHide}
                variant="outline-primary"
                className="d-flex w-100 text-center justify-content-center"
              >
                CANCEL
              </Button>
            </div>
            <div className="col-6">
              <Button
                disabled={disable}
                type="sumbit"
                variant="primary"
                className="d-flex w-100 text-center justify-content-center"
              >
                UPDTAE
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
export default EditProfileModal;
