import React, { useContext, useMemo, useState } from "react";
import { Link } from "gatsby";
import _ from "lodash";
import EditProfileModal from "../../../components/modals/EditProfileModal";
import { CustomerContext } from "../../../context";
import { formatPhoneNumber } from "react-phone-number-input";

const LeftMenu = ({ location }) => {
  const [showEditProfile, setShowEditProfile] = useState(false);

  const { customer } = useContext(CustomerContext);

  const pathname = useMemo(() => {
    if (_.isEmpty(location)) return "";
    return _.lowerCase(_.replace(location.pathname, "/myaccount/", ""));
  }, [location]);

  return (
    <>
      <EditProfileModal
        show={showEditProfile}
        onHide={() => {
          setShowEditProfile(false);
        }}
      />
      <div className="osahan-account-page-left shadow-sm bg-white h-100">
        <div className="border-bottom p-4">
          <div className="osahan-user text-center">
            <div className="osahan-user-media">
              <div className="osahan-user-media-body">
                {customer && (
                  <>
                    <h6 className="mb-2">{customer.displayName}</h6>
                    <div className="mb-1">
                      {formatPhoneNumber(customer.phone) || customer.phone}
                    </div>
                    <div>{customer.email}</div>
                  </>
                )}
                <div className="mb-0 text-black font-weight-bold">
                  <div
                    role="button"
                    className="text-primary mr-3"
                    onKeyPress={() => {}}
                    tabIndex={0}
                    onClick={() => {
                      setShowEditProfile(true);
                    }}
                  >
                    <i className="icofont-ui-edit"></i> EDIT
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ul className="nav flex-column border-0 pt-4 pl-4 pb-4">
          <li className="nav-item myaccount-link">
            <Link
              className={pathname === "orders" ? "nav-link active" : "nav-link"}
              to="/myaccount/orders"
            >
              <i className="icofont-food-cart"></i> Orders
            </Link>
          </li>
          <li className="nav-item myaccount-link">
            <Link
              className={pathname === "addresses" ? "nav-link active" : "nav-link"}
              to="/myaccount/addresses"
            >
              <i className="icofont-location-pin"></i> Addresses
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default LeftMenu;
